<template>
  <div>
    <el-card class="intanle">
      <span class="h1">{{ title }}</span>
      <span @click="goBack()">
        <el-button class="el-button1" type="info" style="float: right">戻る</el-button>
      </span>
    </el-card>
    <el-card>
      <!-- 情報入力 -->
      <el-form ref="form" label-position="right" label-width="20%" :rules="rules" :model="form">
        <el-row>
          <el-col :span="12">
            <el-form-item label="カテゴリ名:" prop="categoryName">
              <el-input placeholder="カテゴリ名を入力してください" v-model="form.categoryName" :disabled="disabled" style="width: 45%"> </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="重み付け:" prop="weight">
              <el-input placeholder="重み付けを入力してください" v-model="form.weight" :disabled="disabled" style="width: 45%"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="カテゴリ画像:" label-width="10%" class="is-required">
              <span class="el-icon-warning-outline" style="color: red"><span style="margin-left: 10px">解析度250px*315px</span> </span>
              <el-upload
                :limit="1"
                :show-file-list="true"
                :file-list="picture1"
                action="#"
                :http-request="uploadImg"
                accept=".jpg,.png,.jpeg,.tif,.bmp,.gif"
                list-type="picture-card"
                :disabled="disabled"
                style="width: 75.5%"
              >
                <i class="el-icon-plus"></i>
              </el-upload>
              <el-dialog :visible.sync="dialogVisible1" style="text-align:center;">
                <img height="400px" :src="picture1s" alt="" />
              </el-dialog>
            </el-form-item>
          </el-col>
        </el-row>

        <div class="logtime" v-if="this.type != 'insert'">
          <el-col :span="12">
            <el-form-item label="作成日時:" label-width="20%">
              {{ createTime }}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="更新日時:" label-width="20%">
              {{ updateTime }}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="作成者:" label-width="20%">
              {{ createPersonName }}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="更新者:" label-width="20%">
              {{ updatePersonName }}
            </el-form-item>
          </el-col>
        </div>
        <el-row>
          <el-col :span="12">
            <el-form-item label="" v-if="this.type != 'details'">
              <el-button type="primary" @click="onSubmit('form')">{{ content }}</el-button>
              <el-button @click="goBack">取消</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-card>
  </div>
</template>

<script>
// import md5 from 'js-md5'
import categoryService from '@/api/category/categoryService'
import axios from 'axios'

export default {
  inject: ['reload'],
  name: 'GetCategoryDetails',
  components: {},
  data() {
    return {
      categoryService: new categoryService(), //インターフェース定義
      type: this.$route.query.type, // 遷移タイプ
      categoryId: this.$route.query.categoryId, // 商品カテゴリ名
      table: false, // ポップアップが表示されているか
      loading: false, //ローディング
      istanchuang: true, //親子間通信の値
      form: {
        categoryName: '',
        weight: '',
        pictureId: ''
      },
      picture1: [], //商品カテゴリ画像
      picture1s: '', //アップロード成功の画像
      upload_btn: false, //画像アップロードボタンの表示
      UploadPicture: '', //アップロード先
      dialogVisible1: false, //画像アップロードポップアップ
      title: '', // 詳細タイトル
      content: '', //内容
      MyHeader: {
        accessToken: '',
        fp: ''
      },
      disabled: false, //編集可能かどうか
      createTime: '', //作成日時
      updateTime: '', //更新日時
      createPersonName: '', //创建人
      updatePersonName: '', //更新人
      headerName: '',
      // 非空のバリデーション
      rules: {
        categoryName: [
          {
            required: true,
            message: 'カテゴリ名を入力してください',
            trigger: 'blur'
          }
        ],
        weight: [
          {
            required: true,
            message: '重み付けを入力してください',
            trigger: 'blur'
          }
        ]
      }
    }
  },

  created() {
    this.UploadPicture = this.$url.UploadPicture
    this.MyHeader.accessToken = localStorage.getItem('accessToken')
    this.MyHeader.currentRole = localStorage.getItem('role')
    this.MyHeader.fp = localStorage.getItem('fp')
    if (this.type == 'details') {
      this.title = '商品カテゴリ詳細'
      this.headerName = '商品カテゴリ詳細'
      this.upload_btn = true
      this.disabled = true
      this.GetCategoryDetail()
    } else if (this.type == 'update') {
      this.title = '商品カテゴリ修正'
      this.headerName = '商品カテゴリ修正'
      this.content = '保存'
      this.GetCategoryDetail()
    } else if (this.type == 'insert') {
      this.title = '商品カテゴリ新規追加'
      this.headerName = '商品カテゴリ新規追加'
      this.content = '追加'
    }
  },

  methods: {
    //商品カテゴリ詳細の取得
    async GetCategoryDetail() {
      const date = {
        accessToken: localStorage.getItem('authToken'),
        currentRole: localStorage.getItem('currentRole'),
        categoryId: this.categoryId //商品カテゴリid
      }
      this.categoryService.detail(date).then(res => {
        if (res.data.success == 'true') {
          this.form = res.data.result
          this.createTime = res.data.result.createTime
          this.updateTime = res.data.result.updateTime
          this.createPersonName = res.data.result.createPerson
          this.updatePersonName = res.data.result.updatePerson
          this.picture1 = []
          if (this.form.pictureId != '') {
            var picIds = this.form.pictureId.split(',')
            for (var i = 0; i < picIds.length; i++) {
              var uuu = {
                url: this.$url.urlcqs + picIds[i]
              }
              this.picture1.push(uuu)
            }
          }
          if (this.picture1.length != 0) {
            this.upload_btn = true
          }
        } else {
          this.$message.error(res.data.message)
        }
      })
    },

    //商品カテゴリ新規
    onSubmit(formName) {
      if (this.type == 'insert') {
        this.$refs[formName].validate(async valid => {
          if (valid) {
            const date = {
              accessToken: localStorage.getItem('authToken'),
              currentRole: localStorage.getItem('currentRole'),
              categoryName: this.form.categoryName,
              weight: this.form.weight,
              pictureId: this.form.pictureId
            }
            if (this.form.pictureId == '') {
              this.$message.error('カテゴリ画像をアップロードしてください！');
              return false;
            }
            this.categoryService.add(date).then(res => {
              if (res.data.success == 'true') {
                this.$message.success('保存成功')
                this.goBack()
              } else {
                this.$message.error(res.data.message)
              }
            })
          }
        })
      } else if (this.type == 'update') {
        this.UpdateCategory(formName)
      }
    },
    //商品カテゴリ編集
    async UpdateCategory(formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          const date = {
            accessToken: localStorage.getItem('authToken'),
            currentRole: localStorage.getItem('currentRole'),
            categoryId: this.categoryId,
            categoryName: this.form.categoryName,
            weight: this.form.weight,
            pictureId: this.form.pictureId
          }
          if (this.form.pictureId == '') {
            this.$message.error('カテゴリ画像をアップロードしてください！');
            return false;
          }
          this.categoryService.update(date).then(res => {
            if (res.data.success == 'true') {
              this.$message.success('修正成功')
              this.goBack()
            } else {
              this.$message.error(res.data.message)
            }
          })
        } else {
          return false
        }
      })
    },

    //前のページに戻る
    goBack() {
      localStorage.setItem('updateState', true)
      this.$router.go(-1)
    },
    // ポップアップを開く
    openme() {
      this.table = true
    },
    // ポップアップの確認ボタン
    submitForm() {
      var item = this.$refs['eidtList'].passFather()
      if (item == null) {
        item = []
      }
      if (item.length != 0) {
        this.form.merchantName = item.merchantName
        this.form.merchantId = item.merchantId
      }
      this.table = false
      this.tables = false
    },
    // ヘッダー画像アップロードのプレビュー
    handlePictureCardPreview1(file) {
      this.dialogImageUrl = file.url
      this.picture1s = file.url
      this.dialogVisible1 = true
    },
    // ヘッダー画像アップロード失敗時
    error1: function(err, file, fileList) {
      this.$message.error('アップロード失敗')
      if (fileList.length >= 1) {
        this.isMax = true
      } else {
        this.isMax = false
      }
    },
    //ヘッダー画像アップロード成功
    success1(response) {
      if (response.msgCode == 1) {
        this.form.pictureId = response.id
        this.$message.success('画像のアップロードに成功しました')
        this.upload_btn = true
      } else if (response.msgCode !== '1') {
        this.$message({
          type: 'error',
          message: response.message
        })
      }
    },

    //ヘッダー画像アップロード削除
    handleBeforeRemove1(file, fileList) {
      var mm = ''
      if (this.type == 'update' && file.response == undefined) {
        mm = file.url.slice(42)
        return this.$confirm('この操作はデータを永久に削除します。続行しますか?', '確認', {
          confirmButtonText: '確認',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.form.pictureId = ''
          // console.log(this.form.pictureId)
          this.picture1 = []
          this.upload_btn = false
        })
      }
    },
    handleExceed() {
      this.$message.warning('最大で1枚の画像しかアップロードできません！')
    },
    async formDataToBase64(formData) {
      let base64Data = {};
      
      for (let [key, value] of formData.entries()) {
          if (value instanceof File) {
              const fileReader = new FileReader();
              const promise = new Promise((resolve, reject) => {
                  fileReader.onload = function(e) {
                      resolve(e.target.result.split(',')[1]); // 移除"data:image/*;"部分，仅保留Base64数据
                  };
                  fileReader.onerror = reject;
              });
              
              fileReader.readAsDataURL(value);
              base64Data[key] = await promise;
          }
      }
      
      
      return base64Data;
    },
    uploadImg(item) {
      this.loading = true
      if (item.file.size > 10 * 1024 * 1024) {
        this.$message.error('アップロードするファイルのサイズは10MBを超えてはいけません！')
        this.picture1 = []
        return false
      }

      let originalFileExt = item.file.name.split('.')[1]

      // 如果originalFileExt在[jpg,png,jpeg,tif,bmp,gif]存在
      if (['jpg', 'png', 'jpeg', 'tif', 'bmp', 'gif'].indexOf(originalFileExt) == -1) {
        this.$message.error('アップロードファイルの拡張子が正しくない!')
        this.picture1 = []
        return false
      }
      

      let FormDatas = new FormData()
      FormDatas.append('file',item.file);
      let base64Img = '';

      this.formDataToBase64(FormDatas).then(base64Data => {
        base64Img = base64Data;

        let accessToken = localStorage.getItem('authToken');
        let currentRole = localStorage.getItem('currentRole');
        
        let params = {
          accessToken: accessToken,
          currentRole,
          file: base64Img.file,
          originalFileName: item.file.name,
          originalFileExt
        }
        // FormDatas.append('user_token', this.user_token);
        // console.log(this.UploadPics)
        axios({
          url: 'm_uploadFile',
          method: 'post',
          headers: { 'Content-Type': 'application/json' },
          timeout: 30000,
          data: params
        }).then( res => {
          this.form.pictureId = res.data.fileId;
        })
        // this.$http({
        //   method: 'post',
        //   url: this.UploadPics,
        //   headers: { 'Content-Type': 'multipart/form-data' },
        //   timeout: 30000,
        //   data: FormDatas
        //   }).then(res=>{

        //   })
      }).catch(error => {
        console.error('Error converting to Base64:', error);
      });

      
    },
  }
}
</script>

<style lang="less" scoped>
.el-button1 {
  margin-top: 2px;
}

.hide_box /deep/ .el-upload--picture-card {
  display: none;
}

.el-card {
  margin-top: 15px;
}

textarea {
  height: 100px;
}
</style>
