import request from "@/utils/service.js"
export default class categoryService {
  
  list(data) {
    return request({
      url: 'm_getCategoryList',
      method: 'post',
      data: data
    })
  }
  detail(data) {
    return request({
      url: 'm_getCategory',
      method: 'post',
      data: data
    })
  }
  add(data) {
    return request({
      url: 'm_addCategory',
      method: 'post',
      data: data
    })
  }
  update(data) {
    return request({
      url: 'm_updateCategory',
      method: 'post',
      data: data
    })
  }
  delete(data) {
    return request({
      url: 'm_deleteCategory',
      method: 'post',
      data: data
    })
  }
}
